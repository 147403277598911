import React from "react";
import AuthContext from "../AuthContext";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import { db as tcbDb } from "../TcbApp";
class Header extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      linkList: [
        { link: "/", text: "Home" },
        { link: "/token-list", text: "Token list" },
        { link: "/task", text: "Task" },
      ],
    };
  }
  componentDidMount() {
    if (this.context && !this.state.name) {
      const {
        user: { customUserId },
      } = this.context;
      tcbDb
        .collection("user-detail")
        .doc(customUserId)
        .get()
        .then(({ data: [{ name }] }) => {
          this.setState({ name });
        });
    }
  }
  componentWillUnmount() {
    this.props.history.push("/auth", { logout: true });
  }
  render() {
    const loginState = this.context;
    const { location } = this.props;
    return (
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center text-white">
                用户控制台
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {this.state.linkList.map(({ link, text }, i) => (
                    <Link
                      key={i}
                      to={link}
                      className={classNames(
                        "px-3 py-2 rounded-md text-sm font-medium",
                        {
                          "bg-gray-900 text-white": location.pathname === link,
                          "text-gray-300 hover:bg-gray-700 hover:text-white":
                            location.pathname !== link,
                        }
                      )}
                    >
                      {text}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {this.context && (
              <>
                <a className="text-white">{this.state.name}</a>&nbsp;
                <a className="text-white" onClick={this.props.logout} href="#">
                  logout
                </a>
              </>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Header);

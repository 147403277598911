import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { fn as tcbFn } from "./TcbApp";
class Task extends React.Component {
  constructor(props) {
    super(props);
    const { tokenList } = props;
    this.state = {
      selectTokenId: tokenList.length > 0 ? tokenList[0]._id : null,
      signInTask: null,
    };
    this.loadTask = this.loadTask.bind(this);
    this.dispatchToState = this.dispatchToState.bind(this);
  }
  componentDidMount() {
    console.log("did mount", this.state.selectTokenId);
    if (!this.state.selectTokenId) return;
    this.loadTask(this.state.selectTokenId);
  }
  loadTask(tokenId) {
    console.log("loadTask", tokenId);
    this.setState({ selectTokenId: tokenId });
    tcbFn("taskCenter", { tokenId }).then((data) => {
      this.dispatchToState(data);
    });
  }
  dispatchToState(data) {
    console.log({ data });
    const { signInTaskVo } = data;
    console.log(signInTaskVo);
    this.setState({
      signInTask: signInTaskVo,
    });
  }
  render() {
    const { tokenList } = this.props;
    const { selectTokenId, signInTask } = this.state;
    return (
      <>
        {selectTokenId ? (
          <div className="space-x-2">
            {tokenList.map(
              ({ _id, data: { note }, userData: { mobilePhone } }) => {
                const isSelected = _id === selectTokenId;
                return (
                  <button
                    key={_id}
                    className={classNames(
                      "px-2 py-1",
                      isSelected ? "bg-indigo-600" : "bg-indigo-300"
                    )}
                    onClick={isSelected ? null : () => this.loadTask(_id)}
                  >
                    <span
                      className={classNames(isSelected ? "text-white" : "")}
                    >
                      {note} &nbsp; {mobilePhone}
                    </span>
                  </button>
                );
              }
            )}
          </div>
        ) : (
          <>请前往token页面录入token</>
        )}
        {signInTask && (
          <div className="flex h-16 gap-2">
            {signInTask.signInItems.map(({ amount, desc, finish }, index) => (
              <div
                key={index}
                className={classNames(
                  "flex-1 rounded-lg items-center justify-center",
                  { "bg-green-500": finish },
                  { "bg-gray-300": !finish }
                )}
              >
                {amount && `+${amount}`}
                {desc}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ tokenList }) => ({
  tokenList,
});
export default connect(mapStateToProps, null)(Task);

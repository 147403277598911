import React from "react";
import { withRouter } from "react-router";
import { auth } from "./TcbApp";
import AuthContext from "./AuthContext";
class Auth extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const ticket = params.get("ticket");
    this.state = { ticket, doLoginDone: false };
    console.log({ ticket, context: this.context });
  }
  componentDidMount() {
    console.log("did mount", this.context);
    if (this.context) {
      this.props.history.push("/");
      return;
    }
    if (this.state.ticket) {
      auth
        .customAuthProvider()
        .signIn(this.state.ticket)
        .then((state) => {
          console.log("success", state);
          this.props.setLoginState(state);
          this.props.history.push("/");
          return;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  render() {
    const loginState = this.context;
    console.log(this);
    const showText = (text) => (
      <div className="border-4 border-dashed text-center border-gray-200 rounded-lg">
        <h1 className="text-xl py-10">{text}</h1>
      </div>
    );
    // 已登录
    if (loginState) return null;
    if (this.props.history.action === "PUSH") {
      if (this.props.location.state?.logout) {
        return showText("已登出");
      }
      return showText("请从拼单页面跳转登录");
    }
    if (this.state.ticket) {
      if (!this.state.loginDone) {
        // 登录中
        return showText("登录中");
      }
    }
    return showText("参数有误，请从拼单页面跳转登录");
  }
}
export default withRouter(Auth);

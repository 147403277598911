import { createStore } from "redux";
import { token } from "./actiontypes";
const { SET_TOKENS, SET_DEFAULE_TOKEN } = token;

const tokenStr = localStorage.getItem("token");
const initialState = {
  default: null,
  tokenList: tokenStr ? JSON.parse(tokenStr) : [],
};

function tokens(state = initialState, action) {
  switch (action.type) {
    case SET_TOKENS:
      return {
        ...state,
        tokenList: action.payload,
      };
    case SET_DEFAULE_TOKEN:
      return {
        ...state,
        default: action.payload,
      };
    default:
      return state;
  }
}

// export default tokens
const store = createStore(
  tokens,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;

import React from "react";
import { connect } from "react-redux";
import { fn as tcbFn, db as tcbDb } from "./TcbApp";
import TokenForm from "./components/TokenForm";
import TokenTable from "./components/TokenTable";
import { token } from "./store/actiontypes";

const { SET_TOKENS, SET_DEFAULE_TOKEN } = token;
class TokenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenList: [],
      loaded: false,
    };
    this.showEdit = this.showEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.autoSetDefault=this.autoSetDefault.bind(this)
  }
  componentDidMount() {
    // tcbDb
    //   .collection("blf-user-token")
    //   .get()
    //   .then(({ data }) => {
    //     this.setState({ tokenList: data });
    //   });

    this.listDbRef = tcbDb.collection("blf-user-token").watch({
      onChange: ({ docs }) => {
        this.setState({
          tokenList: docs,
          loaded: true,
          editVisible: false,
        });
        this.props.setTokens(docs);
      },
    });
  }
  componentWillUnmount() {
    this.listDbRef.close();
  }
  componentDidUpdate({ defaultTokenId, tokenList, setDefaultToken }) {
    tokenList.find(({ _id }) => _id === defaultTokenId);
    if (!defaultTokenId && tokenList.length > 0) {
      setDefaultToken(tokenList[0]._id);
    }
  }
  autoSetDefault({ defaultTokenId, tokenList, setDefaultToken }) {
    tokenList.find(({ _id }) => _id === defaultTokenId  );
    if (!defaultTokenId && tokenList.length > 0) {
      setDefaultToken(tokenList[0]._id);
    }
  }
  showEdit() {
    this.setState({ editVisible: true });
  }
  onSubmit(data) {
    console.log("onSubmit", data);
    const { deviceid, token } = data;
    tcbFn("getUserInfo", { deviceid, token })
      .then((userData) => {
        const { mobilePhone, userId } = userData;
        return { data, userData: { mobilePhone, userId } };
      })
      .then((data) => {
        tcbDb
          .collection("blf-user-token")
          .add({ ...data, created: +new Date() });
      })
      .then(() => {
        this.setState({ editVisible: false });
      })
      .catch((e) => {});
    // tcbDb
    //   .collection("blf-user-token")
    //   .add({ data, created: +new Date() })
    //   .then(() => {
    //     this.setState({ editVisible: false });
    //   });
  }
  onDelete(id) {
    console.log("delete", id);
    tcbDb.collection("blf-user-token").doc(id).remove();
  }
  render() {
    const { tokenList } = this.props;
    const { loaded } = this.state;
    if (!loaded && tokenList.length === 0) return <h1>加载中</h1>;
    return (
      <>
        <h1>共录入 {tokenList.length} 条 Token 信息</h1>
        <button
          type="button"
          onClick={this.showEdit}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          新增
        </button>
        {this.state.editVisible && <TokenForm onSubmit={this.onSubmit} />}
        {tokenList.length > 0 && (
          <TokenTable list={tokenList} deleteById={this.onDelete} />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ tokenList, default: defaultTokenId }) => ({
  tokenList,
  defaultTokenId,
});
const mapDispatchToProps = (dispatch) => ({
  setTokens: (v) => dispatch({ type: SET_TOKENS, payload: v }),
  setDefaultToken: (v) => dispatch({ type: SET_DEFAULE_TOKEN, payload: v }),
});
export default connect(mapStateToProps, mapDispatchToProps)(TokenList);

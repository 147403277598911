import { useState } from "react";
export default ({ onSubmit }) => {
  const [deviceid, setDeviceid] = useState("");
  const [token, setToken] = useState("");
  const [note, setNote] = useState("");
  const handleFieldChange = (setFn) => (e) => {
    console.log("change", e.target.value);
    setFn(e.target.value);
  };
  const handleSumbit = (e) => {
    e.preventDefault();
    onSubmit({ deviceid, token, note });
  };
  return (
    <form className="mt-8 space-y-6" onSubmit={handleSumbit}>
      <input type="hidden" name="remember" value="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="deviceid" className="sr-only">
            deviceid
          </label>
          <input
            id="deviceid"
            name="deviceid"
            type="text"
            autoComplete="off"
            required
            value={deviceid}
            onChange={handleFieldChange(setDeviceid)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="deviceid"
          />
        </div>
        <div>
          <label htmlFor="token" className="sr-only">
            token
          </label>
          <input
            id="token"
            name="token"
            type="text"
            required
            value={token}
            autoComplete="off"
            onChange={handleFieldChange(setToken)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="token"
          />
        </div>
        <div>
          <label htmlFor="note" className="sr-only">
            note
          </label>
          <input
            id="note"
            name="note"
            type="text"
            autoComplete="off"
            required
            value={note}
            onChange={handleFieldChange(setNote)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="备注文字"
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg
            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>
        </span> */}
          保存
        </button>
      </div>
    </form>
  );
};

export default ({ list, deleteById }) => (
  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            备注
          </th>
          {/* <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Title
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Role
          </th> */}
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {list.map(({ _id, data, userData }) => {
          return (
            <tr key={_id}>
              <td className="px-6 py-4 whitespace-nowrap">
                {data.note} {userData.mobilePhone}
              </td>
              <td>
                <a
                  href="#"
                  className="text-red-600 hover:text-red-900"
                  onClick={() => deleteById(_id)}
                >
                  删除
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

import AuthContext from "./AuthContext";
import React from "react";
// const Home = () => <h1>Home</h1>;
class Home extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
  }
  componentDidMount(){
      if(!this.context){
          this.props.history.push('/auth')
      }
  }
  render() {
    const loginState = this.context;
    
    console.log('home',{loginState})
    if (!loginState) {
      return (
        <div className="border-4 border-dashed text-center border-gray-200 rounded-lg">
          <h1 className="text-xl py-10">请通过拼单页面跳转登录</h1>
        </div>
      );
    } else {
      return (
        <h2>
          Home page
          {/* <button
            className="bg-blue-300 rounded px-2"
            onClick={this.context.setLoginState.bind(this, null)}
          >
            logout
          </button> */}
        </h2>
      );
    }
  }
}
export default Home;

// import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AuthContext from "./AuthContext";
import tcbApp, { auth } from "./TcbApp";
import Home from "./Home";
import TokenList from "./TokenList";
import Task from './Task'
import Auth from "./Auth";
import Header from "./components/Header";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginState: auth.hasLoginState(),
    };
    this.logout = this.logout.bind(this);
    this.setLoginState = this.setLoginState.bind(this);
  }
  setLoginState(state) {
    this.setState({
      loginState: state,
    });
  }
  logout() {
    console.log(auth);
    auth
      .signOut()
      .then(() => {
        console.log("signout done");
        this.setState({
          loginState: null,
        });
      })
      .catch((e) => {
        console.log("e", e);
      });
  }
  render() {
    return (
      <div className="min-h-screen flex flex-col">
        <AuthContext.Provider value={this.state.loginState}>
          <Router>
            {this.state.loginState && <Header logout={this.logout} />}
            <div className="flex-grow flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-md w-full space-y-8">
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/task" component={Task} />
                  <Route path="/token-list" component={TokenList} />
                  {/* <Route path="/auth" component={Auth} setLoginState={this.setLoginState}/> */}
                  <Route path="/auth">
                    <Auth setLoginState={this.setLoginState} />
                  </Route>
                  <Route component={Home} />
                </Switch>
                {/* <form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" value="true" />
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Email address"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      Sign in
                    </button>
                  </div>
                </form> */}
              </div>
            </div>
          </Router>
        </AuthContext.Provider>
      </div>
    );
  }
}

export default App;

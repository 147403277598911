import cloudbase from "@cloudbase/js-sdk";

const app = cloudbase.init({
  env: "env1-119983",
});

export const db = app.database();

export const auth = app.auth({
  persistence: "local",
});

export const fn = (action, data = {}) =>
  app
    .callFunction({
      name: "blf-user-console",
      data: { action, data },
    })
    .then((res) => {
      console.log({ res });
      const result = res.result; //云函数执行结果
      if (!result) throw new Error();
      return result;
    });
export default app;
